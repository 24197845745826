export default class ParallaxBackground {

    static images = new Set();

    /**
     * @type HTMLElement
     */
    element;

    static onScroll() {
        window.requestAnimationFrame(()=>{
            for (let e of ParallaxBackground.images) e.scroll();
        });
    }


    static init() {
        window.addEventListener('scroll', ParallaxBackground.onScroll, {passive: true});
        window.addEventListener('resize', ParallaxBackground.onScroll, {passive: true});
    }

    static destroy() {
        window.removeEventListener('scroll', ParallaxBackground.onScroll);
        window.removeEventListener('resize', ParallaxBackground.onScroll);
    }

    static load() {
        for (let e of document.querySelectorAll('.bg-parallax')) e.parallax || (e.parallax = new ParallaxBackground(e));
    }

    /**
     *
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.element = element.parentElement;
        this.image = element;
        this.constructor.images.add(this);
        this.scroll();
    }

    scroll() {
        try {
            const rect = this.element.getBoundingClientRect();
            const offsetTop = document.documentElement.scrollTop + rect.top;
            const position = Math.min(Math.max(window.pageYOffset + window.innerHeight - offsetTop, 0) / (window.innerHeight + rect.height), 1);
            this.image.style.transform = 'translateY(' + -this.calcOffset(position) + 'px)';
        } catch (e) {
            ParallaxBackground.images.delete(this);
            console.error(e);
        }
    }

    calcOffset(position) {
        return (this.element.clientHeight - this.image.clientHeight) * position;
    }

}


