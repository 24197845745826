import './pollyfill';
import 'common/js/cookies';
import Collapse from '@prosky/bootstrap/Collapse';
import Modal from '@prosky/bootstrap/Modal';
import '@prosky/bootstrap/init';
import {BSN} from '@prosky/bootstrap/utils';
import Calculator from "./calculator";
import scrollIt from './scroll';
import IMask from 'imask';
import FidRemoveExtension from "../../common/js/FidRemoveExtension";
import ParallaxBackground from './parallax-vanilla';
import IntersectionObserverExtension from '../../utils/inViewport/IntersectionObserverExtension'
import RellaxExtension from './RellaxExtension';
import AjaxFormExtension from "./AjaxFormExtension";
import Swiper from "./swiper";
/** @type {Naja} */
import Naja from 'naja/src/index';
/** @type {Nette} */
import Nette from '../../common/js/forms.ext';

import {$, $$, d, id, OFF, ON, w} from '../../common/js/utils';
import cookietoggle from "../../common/js/cookietoggle";
import TagManagerExtension from "./TagManagerExtension";

/**
 * Displays or hides HTML element.
 */
Nette.toggle = function (selector, visible) {
    if (/^\w[\w.:-]*$/.test(selector)) { // id
        selector = '#' + selector;
    }
    for (let el of d.querySelectorAll(selector)) {
        el.classList[visible ? 'remove' : 'add']('toggled');
        if (el.Collapse) el.Collapse[visible ? 'show' : 'hide']();
    }
};


export default class ApplicationExtension {
    /** @type {Naja} */
    naja = null;
    /** @type {string|null} */
    lang = d.documentElement.lang;

    /**
     * @param {Naja} naja
     */
    constructor(naja) {
        this.naja = naja;
        window.naja = naja;
        naja[ON]('init', this.init.bind(this));
        naja[ON]('load', this.load.bind(this));
        naja[ON]('success', this.success.bind(this));
        naja[ON]('before', this.before.bind(this));
        naja.snippetHandler[ON]('beforeUpdate', this.beforeUpdate.bind(this));
        naja.formsHandler.netteForms = Nette;
    }

    init(el) {

        this.navigation = d.getElementById('navigation');
        this.navWatch = new NavWatch(this.navigation);
        if (window.location.hash && (el = d.getElementById(window.location.hash.substring(1)))) {
            this.scrollTo(el, 'auto');
        }
        this.navigation[ON]('click', (e, match, el) => {
            let a = e.target.closest('a');
            if (a && (match = a.href.match(/\/?#(\w+)/i)) && (el = d.getElementById(match[1]))) {
                const collapse = this.navigation.querySelector('[data-toggle="collapse"]');
                collapse && collapse.offsetWidth && collapse.Collapse.hide();
                this.scrollTo(el);
                e.preventDefault();
            }
        });
        Nette.focusElement = (el) => {
            this.scrollTo(el, true, () => el.focus({
                preventScroll: true
            }));
        };
        ParallaxBackground.init();
    }

    beforeUpdate({snippet}) {
        this.hideModals(snippet);
        this.hidePopovers(snippet);
        this.destroyMasks(snippet);
    }

    hideModals(snippet) {
        for (let popover of $$('[data-toggle=modal]', snippet)) {
            popover.Modal?.hide();
        }
    }

    hidePopovers(snippet) {
        for (let popover of $$('[data-toggle=popover]', snippet)) {
            popover.Popover?.hide();
        }
    }

    before({detail}) {
        let {url, options} = detail;
        /*if (url.includes('do='))
            options.history = false;*/
        if (url.includes('#'))
            this.hash = url.split('#')[1];
    };

    load() {
        ParallaxBackground.load();
        this.hash = null;
        this.navWatch.update();
        BSN.initCallback();
        cookietoggle();
        for (let el of $$('.modal.show')) {
            el.classList.remove('show');
            let modal = new Modal(el);
            modal.show();
        }
        Calculator.load(d);
        for (let el of $$('[data-mask]')) {
            if (!el.IMask) {
                const options = JSON.parse(el.dataset.mask);
                options.regex && (options.mask = this.toRegexp(options.regex));
                el.IMask = new IMask(el, options);
            }
        }
        for (let el of $$('[data-swiper]')) {
            if (!el.swiper) {
                el.swiper = new Swiper(el, el.dataset.swiper ? JSON.parse(el.dataset.swiper) : {});
            }
        }

        for (let btn of $$('.smooth-scroll')) {
            if (!btn.smoothScroll) {
                btn.smoothScroll = (e) => {
                    e.preventDefault();
                    const target = $(btn.getAttribute('data-target') || btn.getAttribute('href'));
                    console.log(target);
                    if (target) {
                        this.scrollTo(target);
                    }
                };
                btn.addEventListener('click', btn.smoothScroll);
            }

        }
    };

    toRegexp(string) {
        const regParts = string.match(/^\/(.*?)\/([gim]*)$/);
        if (regParts) {
            return new RegExp(regParts[1], regParts[2]);
        }
        return new RegExp(string);
    }

    success({detail}, el) {
        let {payload} = detail;
        if (payload.scrollTop) {
            if (this.hash && (el = d.getElementById(this.hash))) {
                this.scrollTo(el, false);
            } else {
                window.scrollTo(0, 0);
            }
        } else {
            if (this.hash && (el = d.getElementById(this.hash))) {
                this.scrollTo(el, false);
            }
        }
    };

    scrollTo(el, smooth = true, callback) {
        if (el) {
            const offset = window.scrollY + el.getBoundingClientRect().top - this.navigation.offsetHeight - 80;
            const duration = Math.abs(window.pageYOffset - offset);
            if (smooth) {
                scrollIt(offset, Math.min(duration / 3, 700), 'linear', callback);
            } else {
                window.scrollTo(0, offset);
            }
        }
    }

    destroyMasks(snippet) {
        for (let el of $$('[data-mask]', snippet)) {
            el.IMask?.destroy();
        }
    }
}

class NavWatch {

    active = false;

    constructor(navigation) {
        this.navigation = navigation;
        this.update();
    }

    update() {
        this.header = id('main-header');
        if (this.header && !this.active) {
            w[ON]('scroll', this.onScroll);
        }
        if (!this.header && this.active) {
            w[OFF]('scroll', this.onScroll);
        }
        if (this.header) {
            this.onScroll();
        } else {
            this.navigation.classList.remove('top');
        }
    }

    onScroll = () => {
        if (!this.header) {
            w[OFF]('scroll', this.onScroll);
            return;
        }
        let on = window.pageYOffset < this.header.offsetHeight - this.navigation.offsetHeight;
        this.navigation.classList[on ? 'add' : 'remove']('top');
    }
}

Naja.registerExtension(ApplicationExtension);
Naja.registerExtension(TagManagerExtension);
Naja.registerExtension(AjaxFormExtension);
Naja.registerExtension(FidRemoveExtension);
Naja.registerExtension(IntersectionObserverExtension);
Naja.registerExtension(RellaxExtension);
Nette.onDocumentReady(() => Naja.initialize({}));

