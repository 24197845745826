class CookieBar {
    agreeCookieName= 'cookiePolicyAgree';
    agreeValue= 'trueStoryBro';
    year= 365;
    cookiePath= '/';

    bar;

    constructor(el) {
        let btn = el.querySelector('.cookie-btn');
        if (btn) {
            btn.addEventListener('click', this.setAgree.bind(this));
        }
    }

    checkAgree() {
        if (this.getCookie(this.agreeCookieName) === this.agreeValue) {
            this.hideCookieBar();
        } else {
            this.showCookieBar();
        }
    }

    setAgree() {
        this.setCookie(this.agreeCookieName, this.agreeValue, this.year, this.cookiePath);
        this.fadeOutCookieBar();
    }

    hideCookieBar() {
        this.bar.style.display = "none";
    }

    showCookieBar() {
        this.bar.style.display = "block";
    }

    fadeOutCookieBar() {
        this.hideCookieBar();
    }

    setCookie(cname, cvalue, exdays, path) {
        let date = new Date();
        date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = 'expires=' + date.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires + '; path=' + path;
    }

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return "";
    }
}


const barEl = document.getElementById("cookies-bar");
if (barEl) {
    const bar = new CookieBar(barEl);
    bar.checkAgree();
}

