import 'intersection-observer';
import Rellax from "rellax";

export default class RellaxExtension {

    observer;

    options = {
        rootMargin: '100px',
        threshold: 0
    };

    /**
     * @param {Naja} naja
     */
    constructor(naja) {
        this.preferReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
        if (!this.preferReducedMotion.matches) {
            if (naja.initialized) {
                this.init();
            } else {
                naja.addEventListener('init', this.init.bind(this), {one: true});
            }
            naja.snippetHandler.addEventListener('afterUpdate', (event) => this.load(event.detail.snippet));
        }
    }

    init() {
        this.observer = new IntersectionObserver(this.callback.bind(this), this.options);
        this.load()
    }

    load(snippet = document.body) {
        for (let element of snippet.querySelectorAll('.rellax:not(.rellax-initialized)')) {
            if (!element.rellax) {
                this.observer.observe(element);
            }
        }
    }

    callback(entries, observer) {
        for (let entry of entries) {
            let {target} = entry;
            if (entry.isIntersecting) {
                observer.unobserve(target);
                target.classList.add('rellax-initialized');
                target.rellax = new Rellax(target, {
                    breakpoints: [480, 768, 992]
                });
            }
        }
    }

}
