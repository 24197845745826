import {$$} from "../../common/js/utils";

export default class AjaxFormExtension {

    /**
     * @param {Naja} naja
     */
    constructor(naja) {
        naja.snippetHandler.addEventListener('afterUpdate', (event) => this.load(event.detail.snippet));
        if (naja.initialized) {
            this.load();
        } else {
            naja.addEventListener('init', (event) => this.load(event.detail.snippet));
        }
    }


    load(snippet = document.body) {
        for (let form of $$('form.autosubmit', snippet)) {
            form.classList.remove('autosubmit');
            let timeout;

            let submit = (event) => {
                clearTimeout(timeout);
                let selector = event.target.dataset.await;
                if (selector) {
                    for (let el2 of form.querySelectorAll(selector)) {
                        el2.classList.add('loading');
                    }
                }
                timeout = setTimeout(() => {
                    form.elements['autosubmit'].click();
                }, 1500);
            };

            form.addEventListener('keyup', submit);
            form.addEventListener('change', submit);
        }
    }


}
