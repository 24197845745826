import {CountUp} from 'countup.js';

export default class Calculator {

    static load(snippet) {
        for (let el of snippet.querySelectorAll('[data-calculator]')) {
            if (!el.calculator) el.calculator = new Calculator(el);
        }
    }

    constructor(el) {
        this.el = el;
        this.options = JSON.parse(el.dataset.calculator);
        this.form = el.closest('form');
        this.priceEl = el.querySelector('[data-price]');
        this.countup = new CountUp(this.priceEl, 0, {
            separator: '.',
            duration: .35
        });
        el.addEventListener('change', this.update.bind(this));
        this.update();
    }

    update() {
        const inputs = this.el.querySelectorAll('input:checked');
        this.countup.update(Array.from(inputs).reduce((price, input) => {
            return price + this.options.prices[input.name][input.value];
        },0));
    }
}
