import Nette from 'nette-forms';

Nette.focusElement = (el) => el.focus();
Nette.showFormErrors = (form, errors) => {
    let focusElem;
    for (let _errors of form.querySelectorAll('ul.invalid-feedback')) {
        _errors.innerHTML = '';
    }
    for (let {element, message} of errors) {
        let _errors = element.closest('.form-group').querySelector('ul.invalid-feedback');
        if (_errors) {
            _errors.innerHTML += `<li>${message}</li>`;
        }
        if (!focusElem && element.focus) {
            focusElem = element;
        }
    }
    if (focusElem) {
        Nette.focusElement(focusElem);
    }
};
Nette.validators.AppFormsValidators_fill = (element, arg) => Boolean(element.value = arg);
export default Nette;