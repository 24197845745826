export const
    $ = (selector, element = document) => element.querySelector(selector),
    $$ = (selector, element = document) => element.querySelectorAll(selector),
    id = (selector, element = document) => element.getElementById(selector),
    d = document,
    w = window,
    log = console.log,
    OFF = 'removeEventListener',
    ON = 'addEventListener',
    off = (el, events, ...args) => {
        if (!el[OFF]) {
            for (let _el of el) off(_el, events, ...args);
        } else if (typeof events === 'string') {
            el[OFF](events, ...args);
        } else {
            for (let event of events) el[OFF](event, ...args);
        }
    },
    on = (el, events, ...args) => {
        if (!el[ON]) {
            for (let _el of el) on(_el, events, ...args);
        } else if (typeof events === 'string') {
            el[ON](events, ...args);
        } else {
            for (let event of events) el[ON](event, ...args);
        }
    };
