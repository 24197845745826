export default class TagManagerExtension {
    constructor(naja) {
        this.naja = naja;
        naja.addEventListener('load', this.load.bind(this));
        //naja.addEventListener('load',this.load.bind(this));
    }

    load() {
        if (window.googleTagManagerCode) {
            this.gtag('config', window.googleTagManagerCode);
        }
        for (let el of document.querySelectorAll('[data-event]')) {
            if (!el.gtagEvent) {
                el.gtagEvent = true;
                el.addEventListener('click', () => {
                    this.gtag('event', el.dataset.event);
                });
            }
        }
    }

    gtag(...args) {
        if (typeof window.gtag === 'function') {
            window.gtag(...args);
        }
    }
}
