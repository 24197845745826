import 'intersection-observer';

export default class IntersectionObserverExtension {

    observer;

    options = {
        rootMargin: '100px',
        threshold: 0
    };

    /**
     * @param {Naja} naja
     */
    constructor(naja) {
        if (naja.initialized) {
            this.init();
        } else {
            naja.addEventListener('init', this.init.bind(this), {one: true});
        }
        naja.snippetHandler.addEventListener('afterUpdate', (event) => this.load(event.detail.snippet));
    }

    init() {
        this.observer = new IntersectionObserver(this.callback.bind(this), this.options);
        this.load()
    }

    load(snippet = document.body) {
        for (let element of snippet.querySelectorAll('[data-in-viewport]:not(.in-viewport)')) {
            this.observer.observe(element);
        }
    }

    callback(entries, observer) {
        for (let entry of entries) {
            let {target} = entry;
            target.classList.toggle('in-viewport', entry.isIntersecting);
            if (entry.isIntersecting) {
                if (target.getAttribute('data-in-viewport') !== 'continuous') {
                    observer.unobserve(entry.target);
                }
            }
        }
    }

}
