export default class FidRemoveExtension {

    /**
     * @param {Naja} naja
     */
    constructor(naja) {
        this.naja = naja;
        naja.addEventListener('load', this.removeFid.bind(this));
    };


    removeFid() {
       if(window.history){
           let url = new URL(window.location);
           let params = url.searchParams;
           if(params.get('fid')){
               params.delete('fid');
               window.history.replaceState({},document.title,url.toString());
           }
       }
    }

}