if (!window.location.origin) {
    const { protocol, hostname, port } = window.location;
    window.location.origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}

import 'core-js/features/global-this';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'custom-event-polyfill';
import 'element-closest-polyfill';
import 'whatwg-fetch';
//import 'promise-polyfill/src/polyfill';
//import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
//import 'abortcontroller-polyfill';
