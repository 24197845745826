// Swiper Class
import Swiper from 'swiper/src/components/core/core-class';

// Core Modules
import Device from 'swiper/src/modules/device/device';
import Support from 'swiper/src/modules/support/support';
import Browser from 'swiper/src/modules/browser/browser';
import Resize from 'swiper/src/modules/resize/resize';
import Observer from 'swiper/src/modules/observer/observer';

//IMPORT_COMPONENTS
//import Fade from 'swiper/src/components/effect-fade/effect-fade';
import Navigation from 'swiper/src/components/navigation/navigation';
import Autoplay from 'swiper/src/components/autoplay/autoplay';
import Parallax from 'swiper/src/components/parallax/parallax';
import Fade from 'swiper/src/components/effect-fade/effect-fade';

const components = [
    Device,
    Support,
    Browser,
    Resize,
    //INSTALL_COMPONENTS
    Autoplay,
    Fade,
    Parallax,
    Navigation
];

if (typeof Swiper.use === 'undefined') {
    Swiper.use = Swiper.Class.use;
    Swiper.installModule = Swiper.Class.installModule;
}

Swiper.use(components);

export default Swiper;
