import {d, ON,$$} from "./utils";
export default function load(){
    for (let el of $$('[data-cookie-toggle]')) {
        const key = el.dataset.cookieToggle;
        el.removeAttribute('data-cookie-toggle');
        el.toggle = (on)=>{
            let date = new Date();
            date.setTime(date.getTime() + (20 * 60 * 1000));// + 20 minutes
            d.cookie = `${key}=${on};path=${el.dataset.path||'/'};expires=${date.toUTCString()}`;
        };
        if(el.classList.contains('collapse')){
            for (let event of ['show.bs.collapse', 'hide.bs.collapse']) {
                el[ON](event, (e) => el.toggle(e.type === 'show.bs.collapse'));
            }
        }
    }
}